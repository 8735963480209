import { TextInlineLink } from '../../../../../components/cms/wiki_functions';

export function Malatia() {

    return {
        "date": <span><span className="badge bg-danger">CLASSIFIED</span></span>,
        "owned": <span><TextInlineLink name={"bs_state_republic_of_tiara_minor"} /></span>,
        "habitats": <span><span className="badge bg-danger">CLASSIFIED</span></span>,
        "people": <span><TextInlineLink name={"bs_people_agathonians"} /> (<TextInlineLink name={"bs_spec_humans"} text={"Homo sapiens"} />)</span>,
        "moons": <span><span className="badge bg-danger">CLASSIFIED</span></span>,
        "g": <span><span className="badge bg-danger">CLASSIFIED</span></span>,
        "atmosphere": <span><span className="badge bg-danger">CLASSIFIED</span></span>,
        "biosphere": <span><span className="badge bg-danger">CLASSIFIED</span></span>,
        "system": <span><span className="badge bg-danger">CLASSIFIED</span></span>,
        "sector": <span>-</span>,
        "type": <span><span className="badge bg-danger">CLASSIFIED</span></span>,
        "region": <span><TextInlineLink name={"bs_space_democratic_space"} /></span>,
        "area": <span><span className="badge bg-danger">CLASSIFIED</span></span>,
        "imgs": [{ "short": "bs_wiki_malatia_map_en", "big": "bs_wiki_oecumene_map_en" }],
        "text":
            <>
                <h3 className="title-bg">History</h3>
                <p className="card-text text-justify">
                    Little is known about <span className="strong">Malatia</span>. <TextInlineLink name={"bs_planet_agathon"} text={"Agathon's"} /> secret military facilities are located there, so everything about the exact coordinates of the planet or its parameters is kept in the strictest confidence.
                </p>
            </>
    }
}

