import { TextInlineLink } from '../../../../../components/cms/wiki_functions';

export function Mirage() {
    return {
        "date": <span>~4800 <TextInlineLink name={"bs_timeline"} text={"BT"} /></span>,
        "type": <span>yellow dwarf</span>,
        "stars": <span>1</span>,
        "planets": <span>11</span>,
        "habitable": <span>1</span>,

        "region": <span><TextInlineLink name={"bs_space_brienne_cluster"} /></span>,
        "sector": <span><TextInlineLink name={"bs_space_ariadne_sector"} text={"Ariadne"} /></span>,
        "area": <span><TextInlineLink name={"bs_space_hellesian_run"} /></span>,
        "owned": <span><TextInlineLink name={"bs_state_cydonian_priority"} /></span>,
        "people": <span><TextInlineLink name={"bs_people_cydonians"} />, Bassians (<TextInlineLink name={"bs_spec_humans"} text={"Homo sapiens"} />)</span>,
        "population": <span>192 billion</span>,
        "imgs": [{ "short": "bs_wiki_mirage_map_en", "big": "bs_wiki_oecumene_map_en" }],
        "text":
            <>
                <h3 className="title-bg">History</h3>
                <p className="card-text text-justify">
                    <span className="strong">Mirage</span> is a yellow and white F-class star in the Brienne cluster. The name, translated from Cydonian, means “peculiar beauty” or “splendor.” It’s home to <TextInlineLink name={"bs_planet_cydonia"} text={"Cydonia"} /> and a dozen other once lifeless planets, now successfully terraformed for agriculture and, in some areas, light industry.
                </p>
                <p className="card-text text-justify">
                    Most life on these worlds is tucked underground due to the extreme surface temperatures. Despite lacking major industrial power, the Mirage system is self-sufficient, with Cydonia’s necessities flowing in from its neighboring planets. This rare self-sufficiency has turned the entire system into a sprawling, interconnected metropolis.
                </p>
            </>
    }
}

