import { TextInlineLink } from '../../../../../components/cms/wiki_functions';

export function Ardaline_network_planets() {
    return {
        "date": <span><span className="badge bg-danger">CLASSIFIED</span></span>,
        "type": <span>gas-dust nebula</span>,
        "owned": <span><TextInlineLink name={"bs_state_republic_of_tiara_minor"} /></span>,

        "region": <span><TextInlineLink name={"bs_space_democratic_space"} /></span>,
        "people": <span><TextInlineLink name={"bs_people_agathonians"} />, <TextInlineLink
            name={"bs_state_tribalon_republic"} text={"Tribalonians"} /> (<TextInlineLink name={"bs_spec_humans"}
                text={"Homo sapiens"} />)</span>,
        "population": <span><span className="badge bg-danger">CLASSIFIED</span></span>,
        "imgs": [{ "short": "bs_wiki_ardaline_network_planets_map_en", "big": "bs_wiki_oecumene_map_en" }],
        "text":
            <>
                <h3 className="title-bg">History</h3>
                <p className="card-text text-justify">
                    <span className="strong">Ardalynne net</span> – a small nebula between sectors <TextInlineLink name={"bs_space_breeze_sector"} text={"Breeze"} /> and <TextInlineLink name={"bs_space_vikoveera"} text={"Vikoveera"} />. The exact number of planets and space structures is classified, as are the coordinates of all objects.
                </p>
                <p className="card-text text-justify">
                    Without exception, all worlds of the Net serve as training bases for the Agathon armies, sources of raw materials for scientific institutes, or testing grounds for new weapons. There are no planets of civilian use.
                </p>
            </>
    }
}

