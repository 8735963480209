import { TextInlineLink } from '../../../../../components/cms/wiki_functions';
import { Appearance } from "../../../../../components/appearance";

export function Mereglis_sonya() {
    return {
        "has_spoilers": true,
        "spoilers_list": <TextInlineLink name={"book_cydonian_girl"} />,
        "name": "Sonya Mergelis",
        "born": <span>140 <TextInlineLink name={"bs_timeline"} text={"BT"} /></span>,
        "age": <span>139</span>,
        "fullname": <span>Sonya Mergelis</span>,
        "homeworld": <span><TextInlineLink name={"bs_planet_earth"} /></span>,
        "nation": <span><TextInlineLink name={"bs_people_earthlings"} text={"Earthling"} /></span>,
        "species": <span><TextInlineLink name={"bs_spec_humans"} text={"Homo sapiens"} /></span>,
        "work": <span>convicted war criminal, <s>Grand Admiral in the Navy of <TextInlineLink
            name={"bs_state_the_great_monarchy"} /></s></span>,
        "imgs": [
            { "short": "bs_char_mereglis_sonya_1_jpg_500", "big": "bs_char_mereglis_sonya_1_jpg_full" },
        ],
        "pint": "",
        "appears":
            [
                <div className="col-lg-2 col-md-6 text-center">
                    <Appearance name={"book_cydonian_girl"} />
                </div>
            ],
        "text":
            <>
                <h3 className="title-bg">About the character</h3>
                <p className="card-text text-justify">
                    <span className="strong">Sonya</span> is a native earthling, born into an honorable family of combat officers. She was raised as a passionate supporter and aggressive defender of the <TextInlineLink name={"bs_char_alkanarra_siel"} text={"Alcanarra"} /> regime, and her upbringing paid off.
                </p>
                <p className="card-text text-justify">
                    From childhood, Sonya aspired to continue the military dynasty and after school she went straight to the military academy on the Luna, the Earth moon. After the academy, she went straight into the Navy, into the middle of the battlefield. It could be said that it was there that the formation of her personality took place. Her superiors and fellow officers described Sonya as a strong-willed, determined, unyielding, and motivated officer capable of carrying out any, even the toughest orders. At the same time absolutely loyal to the earthly leadership.
                </p>
                <p className="card-text text-justify">
                    It was her loyalty, not her outstanding officer skills, that brought her to the top of the <TextInlineLink name={"bs_state_the_great_monarchy"} text={"Great Monarchy"} /> food chain: from a junior lieutenant in charge of material support on a third-rate frigate to Grand Admiral of <TextInlineLink name={"bs_popups_army_echelon_of_the_fives"} text={"Echelon of the Fives"} />, in charge of suppressing a rebellion in an entire country.
                </p>
                <p className="card-text text-justify">
                    In the <TextInlineLink name={"bs_timeline_galactic_war"} text={"First Galactic War"} />, she became famous for her numerous raids into <TextInlineLink name={"bs_planet_agathon"} text={"Agathon"} /> space, disrupting many of the logistical chains of the Tiara Minor Republic' military fleets. During these operations, she repeatedly violated the norms and customs of warfare, but was never punished after the war ended. Her worst act was turning <TextInlineLink name={"bs_planet_esghariot_en_nuvnish"} text={"Esghariot-en-Nouvnish"} /> into Dead Esghariot. It was for this that <TextInlineLink name={"bs_char_lenisaad_victor"} text={"Victor Lenisaad"} /> and many <TextInlineLink name={"bs_people_breezeans"} text={"Breezeans"} /> hated the <TextInlineLink name={"bs_people_earthlings"} text={"Earthlings"} />, and not at all for the war they unleashed against the <TextInlineLink name={"bs_people_agathonians"} text={"Agathonians"} />, who the people of <TextInlineLink name={"bs_space_breeze_sector"} text={"Breeze"} /> don't like very much either.
                </p>
                <p className="card-text text-justify">
                    The end of Sonya's career came unexpectedly, a year <TextInlineLink name={"bs_timeline"} text={"before Tamanrasett"} />. During the <TextInlineLink name={"bs_timeline_the_shark_revolt"} text={"Mihjelm Rebellion"} />, which she was determined to violently suppress, <TextInlineLink name={"bs_char_de_karma_ivar"} text={"Ivar De Karma"} /> managed to disable most of her fleet with a hacking attack. Then, without much resistance, he invaded the <TextInlineLink name={"bs_star_njorun"} text={"Njörun"} /> system and took Sonya prisoner. After that, she participated in meetings of the overthrown Earth government, but solely as an advisor to the throne-dropped <TextInlineLink name={"bs_char_alkanarra_siel"} text={"Ciel Alcanarra"} />. However, her closeness to the former ruler did not save the girl: months later, when the instability in <TextInlineLink name={"bs_space_the_old_space"} text={"Old Space"} /> waned, the <TextInlineLink name={"bs_planet_michelm"} text={"Mihjelms"} /> sent her to be court-martialed.
                </p>
                <p className="card-text text-justify">
                    Sonja was tried not only for her crimes during the rebellion, but for her actions in the Galactic War. The list of charges was too long and beyond the jurisdiction of the Mihjelms. So after being sentenced to life imprisonment on Earth, the girl was extradited to Agathon, where the charges were joined by "questions" from the affected inhabitants of <TextInlineLink name={"bs_space_democratic_space"} text={"Demspace"} />. There, Sonya was sentenced to six more life sentences and sent to serve her time in a labor camp.
                </p>
            </>
    }

}