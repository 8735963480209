import { CrumbsBlackSunTimeline } from "../../../../../components/Bread_Crumbs";

import { GetQuotes } from "../../../../../components/Quotes";
import { GetConnections, TextInlineLink } from '../../../../../components/cms/wiki_functions';

export function Galactic_war() {
    document.title = "Galactic war || Cydonian girl & Black sun"
    return (<>
        <CrumbsBlackSunTimeline name={"Galactic war"} />

        <section id="about">
            <div className="about-me container">
                <div className="section-title"><h2>Galactic war</h2></div>
                <div className="row">
                    <div className="col-lg-12 pt-4 pt-lg-0 content" data-aos="fade-left">
                        <GetQuotes name={"bs_timeline_galactic_war"} />
                        <div className="row">
                            <div className="col-lg-6">
                                <ul>
                                    <li>
                                        <i className="fas fa-chevron-right"></i> <strong>Dates: </strong>
                                        <span>~37-25 <TextInlineLink name={"bs_timeline"} text={"BT"} /></span>
                                    </li>
                                </ul>
                            </div>
                            <div className="col-lg-6">
                                <ul>
                                    <li>
                                        <i className="fas fa-chevron-right"></i> <strong>Places:</strong>
                                        <span><TextInlineLink name={"bs_space_the_old_space"} />, <TextInlineLink
                                            name={"bs_space_democratic_space"} /></span>
                                    </li>
                                </ul>
                            </div>
                        </div>
                        <ul>
                            <li>
                                <i className="fas fa-chevron-right"></i> <strong>Participants:</strong>
                                <span><TextInlineLink name={"bs_state_cydonian_priority"} />, <TextInlineLink
                                    name={"bs_state_the_great_monarchy"} />, <TextInlineLink
                                        name={"bs_state_republic_of_tiara_minor"} />, <TextInlineLink
                                        name={"bs_state_tribalon_republic"} />, <TextInlineLink
                                        name={"bs_state_aethurian_republic"} />, <TextInlineLink
                                        name={"bs_state_ajliraenian_domination"} /> and many others</span>
                            </li>
                        </ul>
                        <p><strong>Related
                            articles:</strong> <>{GetConnections("bs_timeline_galactic_war").map(conn => (conn))}</>
                        </p>
                    </div>
                </div>
            </div>
        </section>

        <div className="row row-alt">
            <div className="col-lg-12">
                <h3 className="title-bg">Story</h3>
                <p className='card-text text-justify'>
                    <span className="strong">The First Galactic War</span> is a nasty mess the previous Monarch (may his name be forgotten) started. He used as a formal reason the interference of <TextInlineLink name={"bs_comp_cms"} text={"TMS"} /> in the affairs of a couple of small kingdoms on the border of neutral zone between spheres of influence of democrats and monarchists. The war went on for twelve years: it killed millions of people throughout the galaxy and wiped out innumerable other species of living beings from the face of the universe. For the most part, the Theater of War spread out in <TextInlineLink name={"bs_space_the_old_space"} text={"Old Space"} />, deep within <TextInlineLink name={"bs_planet_earth"} text={"Earth's"} /> domain. The Monarch may have declared war, but how to wage it - had no idea, so quickly lost the initiative due to a number of stupid decisions and then twelve years just trying to survive and not to lose the empire. He did not succeed.
                </p>
            </div>
        </div>
    </>)
}
