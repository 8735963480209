import { TextInlineLink, TextPopup } from '../../../../../components/cms/wiki_functions';

export function Aethurian_republic() {
    return {
        "capital": <span><TextPopup
            name={"bs_popups_planet_aethuria"} /></span>,
        "form": <span>presidential republic</span>,
        "currency": <span><TextInlineLink name={"bs_currency_teros"} text={"Agathonian teros"} /></span>,
        "langs": <span><TextInlineLink name={"bs_lang_arum"} text={"Arum"} />, <TextInlineLink
            name={"bs_lang_earth"} text={"Earthlin"} /></span>,
        "leader": <span>president</span>,
        "region": <span><TextInlineLink name={"bs_space_democratic_space"} /></span>,
        "sectors": <span>1</span>,
        "worlds": <span>23 (3 metropolises)</span>,
        "people": <span><TextInlineLink name={"bs_people_aethurians"} />, <TextInlineLink
            name={"bs_people_agathonians"} />, <TextInlineLink name={"bs_people_earthlings"} /></span>,
        "imgs": [{ "short": "bs_wiki_aethurian_republic_map_en", "big": "bs_wiki_oecumene_map_en" }],
        "text":
            <>
                <h3 className="title-bg">History</h3>
                <p className="card-text text-justify">
                    <span className="strong">Aethurian republic</span> – state on the edge of Democratic Space with its capital on Aethuria. Borders <TextInlineLink name={"bs_state_the_great_monarchy"} /> and <TextInlineLink name={"bs_state_republic_of_tiara_minor"} />. Many trade routes pass through Aethurian space because it is perfectly situated in the heart of populated galaxy. The path from <TextInlineLink name={"bs_planet_cydonia"} /> to <TextInlineLink name={"bs_planet_agathon"} /> via Aethuria is not the shortest, but it is very convenient to unload your goods here to send them to different countries of the center of <TextInlineLink name={"bs_maps_oecumene"} text={"oecumene"} /> with minimal logistics costs.
                </p>
                <p className="card-text text-justify">
                    The Republic appeared on the map immediately after <TextInlineLink name={"bs_timeline_galactic_war"}
                        text={"Galactic war"} /> when the
                    weakened
                    Earthlings were unable
                    to hold on to their previous conquests. The Aethurians themselves defected to the side
                    of <TextInlineLink
                        name={"bs_planet_agathon"} text={"Agathon"} /> even before the war began and
                    actively advocated separation from the Monarchy. For this, the Earth Navy had repeatedly raided
                    Aethurian businessmen and disrupted the logistics of trade routes. Military force was avoided at the
                    time
                    because of the proximity of Agathon territories.
                </p>
                <p className="card-text text-justify">
                    After gaining independence, Aethuria flourished with many <TextInlineLink name={"bs_tech_hyperrun"} text={"trade routes"} />, running through it: from small ones (like the Cyrenaic Way) to galactic-scale trails scale (Earth - Agathon - Ternerian end).
                </p>
                <p className="card-text text-justify">
                    For 1 year <TextInlineLink name={"bs_timeline"} text={"BT"} /> The Aethurian Republic was considered
                    the standard
                    of a post-colonial state that managed to
                    throw off the shackles of monarchism.
                </p>
            </>
    }
}

